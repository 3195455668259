import React, { useEffect } from "react";
import { setCurrentUri } from "../../modules/AppContext/appContextSlice";
import { useAppDispatch } from "../../modules/redux";
import { Layout } from "../../containers";
import SEO from "./SEO";
import { ScrollToTop } from "../../common/ScrollToTop";

interface PageProps {
  children: JSX.Element | Array<JSX.Element>;
  title: string;
  description: string;
  uri: string;
}

export const Page: React.FC<PageProps> = (props: PageProps) => {
  const { children, title, description, uri } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentUri(uri));
  }, []);

  return (
    <div className="page">
      <SEO title={title} description={description} />
      <Layout>{children}</Layout>
      <ScrollToTop />
    </div>
  );
};
