import React from "react";
import { Footer, Header } from "../../components";
import "./Layout.scss";

interface LayoutProps {
  children: JSX.Element | Array<JSX.Element>;
}

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { children } = props;

  return (
    <div className="layout">
      <Header />
      <main className="main">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
