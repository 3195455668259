import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { Page } from "../containers";
import { SectionAboutUs, SectionServices, SectionReferences, SectionContact } from "../components";
import { useTranslation } from "gatsby-plugin-react-i18next";

const IndexPage: React.FC<PageProps> = (props: PageProps) => {
  const { uri } = props;
  const { t } = useTranslation();

  return (
    <Page uri={uri} title={t("home_page_title")} description={t("home_page_description")}>
      <SectionAboutUs />
      <SectionServices />
      <SectionReferences />
      <SectionContact />
    </Page>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
